<template>
  <b-modal
    id="addRegistryModal"
    size="lg"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="people"></b-icon>
        <span>Seleziona le Anagrafiche</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn @click="cancel()" size="sm" variant="outline-secondary"
          >CHIUDI</b-btn
        >
        <b-btn
          @click="
            cancel();
            saveEvent();
          "
          size="sm"
          variant="outline-lisaweb"
          >Salva</b-btn
        >
      </b-form-group>
    </template>

    <b-button-group
      v-b-toggle:collapse-1
      class="my-2 filter-button-group"
      :title="getDefaultFilterMessage()"
    >
      <span class="when-open">
        <b-icon icon="funnel" font-scale="1.5"></b-icon>
      </span>
      <span class="when-closed">
        <b-icon icon="funnel" font-scale="1.5"></b-icon>
      </span>
      Filtra
    </b-button-group>

    <b-collapse visible id="collapse-1">
      <div class="mt-2">
        <b-form @submit.prevent="onSearch(filterName)">
          <b-card class="filter">
            <b-row>
              <div class="col-md-6">
                <base-input
                  name="RFRNAME"
                  v-model="filter.byRegistry.title"
                  type="text"
                  label="Nominativo"
                  placeholder="Inserisci un nominativo"
                />
              </div>
              <div class="form-group col-md-4 align-self-end">
                <b-button type="submit" variant="lisaweb" size="sm"
                  >Cerca</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
                  >Reset</b-button
                >
              </div>
            </b-row>
            <b-button-group
              v-b-toggle:collapse-3
              class="my-2 filter-button-group"
            >
              <span class="when-open">
                <b-icon icon="funnel" font-scale="1.5"></b-icon>
              </span>
              <span class="when-closed">
                <b-icon icon="funnel" font-scale="1.5"></b-icon>
              </span>
              Altri Filtri
            </b-button-group>
            <b-collapse id="collapse-3" class="mt-2" v-model="visible">
              <b-row>
                <div class="col-md-6">
                  <base-input
                    name="NINO"
                    type="text"
                    label="Codice Fiscale"
                    v-model="filter.byAttribute.NINO"
                    placeholder="Inserisci un codice fiscale"
                  />
                </div>
                <div class="col-md-6">
                  <base-input
                    name="creg"
                    type="text"
                    label="Partita IVA"
                    v-model="filter.byCustom.CREG.value"
                    placeholder="Inserisci una partita IVA"
                  />
                </div>
                <div class="col-md-6">
                  <base-select
                    name="salesman"
                    label="Produttore"
                    v-model="filter.byBroker.id"
                    :options="salesmen"
                    :multiple="false"
                  />
                </div>
                <div class="col-md-6">
                  <base-select
                    name="localita"
                    label="Località"
                    v-model="filter.byCorrespondence.CITY"
                    placeholder="Digitare le lettere iniziali..."
                    :options="ajaxOptions"
                    @search-change="getAjaxOptions"
                    :isLoading="isLoadingAjax"
                  />
                </div>
                <div class="col-md-12">
                  <base-textarea
                    name="ADDR"
                    v-model="filter.byCustom.ADDR.value"
                    label="Indirizzo"
                    placeholder="Inserisci un indirizzo"
                  />
                </div>
              </b-row>
            </b-collapse>
          </b-card>
        </b-form>
      </div>
    </b-collapse>
    <table-registries
      :hasChecksPaginated="true"
      hasChecks
      noActions
      noCustom
      :fields="fields"
      repository="registry"
      resource="registries"
      :filterOn="{ byRelations: ['byCorrespondence'] }"
      :filterName="filterName"
      :ref="tableRef"
      noInnerWidth
      noInputPaginator
      @singleCheck="handlesingleCheck"
    ></table-registries>
  </b-modal>
</template>
<script>
import TableRegistries from "@/components/tables/Registries";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions, mapGetters } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      tableRef: "AddRegistryModalTableRef",
      filterName: "filterAddRegistryModal",
      filter: this.initFilter(),
      visible: false,
      isLoadingAjax: false,
      checkedRowsIds: [],
      ajaxOptions: [],
      salesmen: [],
      fields: [
        {
          key: "check",
          label: "",
          sortable: false,
          thClass: "check",
          class: "check",
        },
        {
          key: "attributables.SURN",
          label: "Cognome",
        },
        {
          key: "attributables.NAME",
          label: "Nome",
        },
        {
          key: "attributables.CNAM",
          label: "Ragione Sociale",
        },
        {
          key: "attributables.NINO",
          label: "Codice Fiscale",
        },
        {
          key: "attributables.CREG",
          label: "Partita IVA",
        },
        {
          key: "address",
          label: this.getDictionary("attribute_ADDR_value", "correspondence"),
          sortable: false,
        },
      ],
    };
  },
  props: {
    groupId: null,
  },
  components: { TableRegistries, BaseInput, BaseTextarea, BaseSelect },
  computed: {
    canSearch() {
      return (
        this.$refs[this.tableRef] &&
        this.$refs[this.tableRef].isLoading == false
      );
    },
  },
  methods: {
    handlesingleCheck(data) {
      this.checkedRowsIds = data;
    },
    initFilter() {
      let init = {
        byAttribute: {
          CNAME: null,
          NINO: null,
        },
        byBroker: {
          id: null,
        },
        byCustom: {
          CREG: {
            value: null,
            likewise: 2,
            filter: "byAttribute",
          },
          ADDR: {
            value: null,
            likewise: 4,
            filter: "byCorrespondence",
          },
        },
        byRegistry: {
          title: null,
        },
        byCorrespondence: {
          CITY: null,
        },
        byWithoutRegistryGroup: {
          id: `${this.groupId}`,
        },
      };

      return init;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    onSearch(name) {
      // togliere il filterOn dalla table per inibire automatic fetch, e impostare in initFilter
      // ricordarsi di eliminare il filtro subito dopo aver fatto fetch
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    getAjaxOptions(query) {
      if (query.length >= 2) {
        this.isLoadingAjax = true;
        this.ajaxOptions = this.getCouncils()(null, query);
        this.isLoadingAjax = false;
      }
    },
    saveEvent() {
      this.$emit(
        "save",
        this.checkedRowsIds
        // this.$refs[this.tableRef].selectedRows
        // this.$refs[this.tableRef].items
      );
      this.$refs[this.tableRef].selectedRows = [];
    },
    deleteEvent(selectInfo) {
      this.$emit("delete", selectInfo);
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getCouncils: "councils",
    }),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
  beforeMount() {
    /*  */
    console.log("beforeCreate Registries (addRegistryModal)");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("countries"))
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {
            console.info(
              `background data "${path}countries" fetched correctly (addRegistryModal)`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("councils"))
      resources.push(
        this.$store
          .dispatch(`${path}councils`)
          .then(() => {
            console.info(
              `background data "${path}councils" fetched correctly (addRegistryModal)`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("provinces"))
      resources.push(
        this.$store
          .dispatch(`${path}provinces`)
          .then(() => {
            console.info(
              `background data "${path}provinces" fetched correctly (addRegistryModal)`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly (addRegistryModal)");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :| (addRegistryModal)");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /*  */
  },
  mounted() {
    this.salesmen = this.getSalesmen();
  },
};
</script>
<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  /* overflow: auto; unset or auto value is not ok */
  overflow-y: auto;
  max-height: 400px !important; /* prevent external container to scroll*/
  min-height: 100px !important;
}
</style>
